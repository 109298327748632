<template>
  <div ref="charts" :style="style" v-bind="$attrs"></div>
</template>

<script>
import echarts from '@/../public/js/echarts'
export default {
  name: 'echarts',
  data () {
    return {
      chart: ''
    }
  },
  props: {
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '100%'
    },
    option: {
      type: Object,
      default() {
        return {
          title: {
            // text: 'ECharts示例'
          },
          tooltip: {},
          legend: {
            data:['销量']
          },
          xAxis: {
            type: 'category',
            data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
          },
          yAxis: {
            type: 'value'
          },
          series: [{
            data: [820, 932, 901, 934, 1290, 1330, 1320],
            type: 'line'
          }]
        }
      }
    }
  },
  computed: {
    style() {
      return {
        width: this.width,
        height: this.height
      }
    }
  },
  watch: {
    option: {
      handler(newVal) {
        if (this.chart) {
          this.chart.setOption(newVal)
        } else {
          this.init();
        }
      },
      deep: true
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.chart = echarts.init(this.$refs.charts)
      this.chart.setOption(this.option)
      window.addEventListener("resize", this.chart.resize);
    }
  }
}
</script>
